/* src/components/ExpenseTable/ExpenseTable.module.css */
.tableContainer {
    overflow-x: auto;
  }
  
  .table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .table th,
  .table td {
    padding: 0.5rem;
    text-align: left;
    border-bottom: 1px solid #e2e8f0;
  }
  
  .table th {
    background-color: #f7fafc;
    font-weight: 600;
  }