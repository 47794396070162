/* src/components/InputSlider/InputSlider.module.css */
.container {
    margin-bottom: 1rem;
  }
  
  .label {
    display: block;
    font-size: 0.875rem;
    font-weight: 500;
    margin-bottom: 0.25rem;
  }
  
  .sliderContainer {
    display: flex;
    align-items: center;
  }
  
  .slider {
    flex-grow: 1;
    margin-right: 1rem;
  }
  
  .value {
    font-size: 0.875rem;
    font-weight: 500;
  }