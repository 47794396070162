/* src/pages/RentVsBuy/RentVsBuy.module.css */
.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
  }
  
  .title {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 2rem;
  }
  
  .subtitle {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }
  
  .grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
  }
  
  @media (min-width: 1024px) {
    .grid {
      grid-template-columns: 1fr 1fr;
    }
  }