/* src/components/ResultsSummary/ResultsSummary.module.css */
.card {
    color: white;
    margin-bottom: 1rem;
    border-radius: 0.5rem;
    overflow: hidden;
  }
  
  .cardBody {
    padding: 1rem;
  }
  
  .cardTitle {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
  }
  
  .costGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }
  
  .costLabel {
    font-size: 0.875rem;
    opacity: 0.8;
  }
  
  .costValue {
    font-size: 1.25rem;
    font-weight: bold;
  }